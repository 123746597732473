@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@600");

h1, h2 {
  font-family: 'Fraunces', serif;
}

p {
  color: #555;
}
